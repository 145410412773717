<template>
    <div class="access-control">
        <transition name="popup">
            <div class="popup-mask" v-if="isShowPopup == true" @click.self="clearFile()">
                <div class="popup-content">
                    <i class="close bi bi-x" @click="clearFile()"></i>
                    <!-- <div class="title">Access Control</div> -->
                    <div class="title">{{ $t('access-control') }}</div>
                    <div class="content">
                        <div class="file-info">
                            <div class="file-detail">
                                <div class="dept-manage">
                                    <!-- <h6>My Department</h6> -->
                                    <h6>{{ $t('department-list') }}</h6>
                                    <div class="dept-manage-list">
                                        <div class="dept-list">
                                            <ul>
                                                <li class="item" v-for="(item, index) in groupList" :key="index"
                                                    @click="selectDept(item.dept)">{{ item.dept }} &nbsp;&nbsp;<i
                                                        class="bi bi-x-lg" @click="delDept(item.dept)"></i></li>
                                                <button @click="addingNewDept = true;">{{ $t('add-new') }}</button>
                                            </ul>
                                        </div>
                                        <div v-if="addingNewDept" class="dept-input">
                                            <input id="new-department-input" type="text" :placeholder="$t('new-department')"
                                                v-model="newGroupDept">
                                            <!-- <button class="button add-button">Update</button> -->
                                            <button class="button add-button" @click="addDept(newGroupDept)">{{ $t('save-add') }}</button>
                                            <i class="bi bi-x" @click="addingNewDept = false;"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="user-manage">
                                    <!-- <h6>User management</h6> -->
                                    <h6>{{ $t('user-management') }}</h6>
                                    <div class="table">
                                        <div class="table-row-1 row">
                                            <div class="table-cell col-1">
                                                <p>{{ $t('user-id') }}</p>
                                            </div>
                                            <div class="table-cell col-4">
                                                <p>{{ $t('email') }}</p>
                                            </div>
                                            <div class="table-cell col-2">
                                                <p>{{ $t('department') }}</p>
                                            </div>
                                            <div class="table-cell col-1">
                                                <p>{{ $t('level') }}</p>
                                            </div>
                                            <div class="table-cell col-1">
                                                <p>{{ $t('active') }}</p>
                                            </div>
                                            <div class="table-cell col-3">
                                                <p>{{ $t('action') }}</p>
                                            </div>
                                        </div>
                                        <div class="table-row-0 row">
                                            <div class="table-cell col-1"><button v-if="!addingNewUser" class="button"
                                                    @click="addingNewUser = true;">{{ $t('add-new') }}</button></div>
                                            <div v-if="addingNewUser" class="table-cell col-4"><input
                                                    v-model="newUserEmail"></div>
                                            <div v-if="addingNewUser" class="table-cell col-2"><select
                                                    v-model="selectedGroupDept">
                                                    <option></option>
                                                    <option v-for="group in groupList" :key="group.id">{{ group.dept }}
                                                    </option>
                                                </select></div>
                                            <div v-if="addingNewUser" class="table-cell col-1"><select
                                                    v-model="selectedGroupLevel">
                                                    <option></option>
                                                    <option v-for="level in availableGroupLevels" :key="level"
                                                        :value="level"> {{ level }}
                                                    </option>
                                                    <option v-if="availableGroupLevels.length === 0">C</option>
                                                </select></div>
                                            <div v-if="addingNewUser" class="table-cell col-1 switch">
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox"
                                                        v-model="selectedGroupActive">
                                                </div>
                                            </div>
                                            <div v-if="addingNewUser" class="table-cell col-3">
                                                <button class="button create-button"
                                                    @click="createUser(newUserEmail, selectedGroupDept, selectedGroupLevel, selectedGroupActive)">{{ $t('create') }}</button>
                                                <i class="bi bi-x" @click="addingNewUser = false;"></i></div>
                                        </div>
                                        <div class="table-row row" v-for="(user, index) in userList" :key="index">
                                            <div class="table-cell col-1">{{ user.user_id }}</div>
                                            <div class="table-cell col-4">{{ user.email }}</div>
                                            <div class="table-cell col-2" v-if="!user.is_editing">{{ user.doc_department }}</div>
                                            <div class="table-cell col-2" v-if="user.is_editing"><select
                                                    v-model="user.new_department">
                                                    <option></option>
                                                    <option v-for="group in groupList" :key="group.id">{{ group.dept }}
                                                    </option>
                                                </select></div>
                                            <div class="table-cell col-1" v-if="!user.is_editing">{{ user.doc_level }}</div>
                                            <div class="table-cell col-1" v-if="user.is_editing"><select
                                                    v-model="user.new_level">
                                                    <option></option>
                                                    <option v-for="level in availableUserLevels" :key="level"
                                                        :value="level"> {{ level }}
                                                    </option>
                                                    <option v-if="availableUserLevels.length === 0">C</option>
                                                </select></div>
                                            <div class="table-cell col-1 switch">
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox"
                                                        v-model="user.is_enabled" @click="setUserStatus(user)">
                                                </div>
                                            </div>
                                            <div v-if="!user.is_reset && !user.is_editing" class="table-cell col-3"><button class="button"
                                                    @click="resetUserPassword(user)">{{ $t('reset-password') }}</button><button class="button"
                                                    @click="editCurrentUser(user)">{{ $t('modify') }}</button></div>
                                            <div v-if="user.is_reset" class="table-cell col-3 new-password">
                                                <p>{{ user.new_password }}</p><button class="button"
                                                    @click="copyToClipboard(user)">{{ $t('copy') }}</button>
                                            </div>
                                            <div v-if="user.is_editing" class="table-cell col-3"><button class="button"
                                                    @click="updateCurrentUser(user)">{{ $t('update') }}</button><button class="button"
                                                    @click="cancelEditUser(user)">{{ $t('cancel') }}</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import api from '../../api/api.js';

export default {
    name: 'accessControl',
    data() {
        return {
            isShowPopup: true,

            loginStatus: null,

            //department
            originGroupList: [],
            newGroupDept: '',
            groupList: [],
            clickedGroup: '',

            //user
            newUserEmail: '',
            selectedGroupDept: '',
            selectedGroupLevel: '',
            selectedGroupActive: '',
            userList: [],

            addingNewDept: false,
            addingNewUser: false,

            editingUser: [],

            isUploading: false,
        };
    },
    methods: {
        getLoginInfo(user) {
            try {
                this.userList = user.attributes.extra.company_members.reverse();
                this.userList.forEach(user => {
                    user.is_enabled = user.is_enabled ? true : false;
                    user.is_reset = false;
                    user.is_editing = false;
                    user.new_password = '';
                    user.new_department = '';
                    user.new_level = 0;
                    if (user.doc_level == '4') user.doc_level = 'C';
                });
            } catch {
                this.userList = [];
            }
        },

        clearFile() {
            this.doc_id = 0;
            this.currentGroupList = [];
            this.selectedGroupDept = '';
            this.selectedGroupLevel = '';
            this.privateFlag = false;

            this.closePopup();
        },

        closePopup() {
            this.isShowPopup = false;
            this.$emit('toggleAccessControlPopup');
        },

        async getAceessGroupList() {
            const response = await api.getDocumentAceessGroupList();
            if (!response.error && response.data[0].attributes.extra.ricoh) {
                this.originGroupList = response.data[0].attributes.extra.ricoh.result;
                this.groupList = response.data[0].attributes.extra.ricoh.result;

                const uniqueGroups = this.groupList.reduce((acc, item) => {
                    const existingGroup = acc.find(group => group.dept === item.group_dept);
                    if (existingGroup) {
                        existingGroup.lvl.push(item.group_level);
                    } else {
                        acc.push({ dept: item.group_dept, lvl: [item.group_level] });
                    }
                    return acc;
                }, []);
                this.groupList = uniqueGroups;
            }
        },

        async refreshUserList() {
            this.loginStatus = await api.loginStatusChecker();
            if (this.loginStatus == null) {
                this.$router.push({ path: '/' })
            } else {
                this.getLoginInfo(this.loginStatus);
            }
        },

        async delDept(dept) {
            const response = await api.postDeleteAccessGroup(dept);
            if (!response.error) {
                this.getAceessGroupList();
            } else {
                console.error(response);
            }
        },

        async addDept(dept) {
            if (!dept) return;
            this.newGroupDept = '';

            const response = await api.postCreateAccessGroup(dept);
            if (!response.error) {
                this.getAceessGroupList();
            } else {
                console.error(response);
            }
        },

        async selectDept(dept) {
            if (!this.addingNewDept) return;
            if (!dept) {
                document.getElementById('new-department-input').placeholder = this.$t('new-department');
                return;
            }
            document.getElementById('new-department-input').placeholder = dept;
        },

        async createUser(email, dept, level, active) {
            if (!email || !level) {
                alert(this.$t('empty-invalid'));
                return;
            }

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = emailRegex.test(email);
            if (!isValidEmail) {
                alert(this.$t('invalid-email'));
                return;
            }

            if (level == 'C') level = 4;

            this.newUserEmail = '';
            this.selectedGroupDept = '';
            this.selectedGroupLevel = '';
            this.newUserActive = '';

            const response = await api.postCreateAIUser(email);
            if (!response.error) {
                const newId = response.data[0].attributes.extra.aiuser.result.uid;
                this.editUser(newId, dept, level);
                this.setNewUserStatus(newId, active);
            } else {
                console.error(response);
            }
        },

        async editUser(userId, dept, level) {
            const response = await api.postSetUserDeptLevel(userId, dept, level);
            if (!response.error) {
                this.refreshUserList();
            } else {
                console.error(response);
            }
        },

        async editCurrentUser(user) {
            user.new_department = user.doc_department;
            user.new_level = user.doc_level;
            user.is_editing = true;
            this.editingUser = user;
        },

        async updateCurrentUser(user) {
            user.doc_department = user.new_department;
            user.doc_level = user.new_level;

            if (user.new_level === 'C') user.new_level = 4;

            await this.editUser(user.user_id, user.new_department, user.new_level);
            user.is_editing = false;
            this.editingUser = [];
        },

        async cancelEditUser(user) {
            user.is_editing = false;
            this.editingUser = [];
        },

        async setNewUserStatus(userId, status) {
            const response = await api.postSetAIUserStatus(userId, status ? 1 : 0);
            if (!response.error) {
                this.refreshUserList();
            } else {
                console.error(response);
            }
        },

        async setUserStatus(user) {
            if (!user) return;

            const response = await api.postSetAIUserStatus(user.user_id, user.is_enabled ? 0 : 1);
            if (!response.error) {
                console.log(response.data[0].attributes.extra.aiuser);
            } else {
                console.error(response);
            }
        },

        async resetUserPassword(user) {
            if (!user) return;
            user.is_reset = true;
            user.new_password = 'waiting...';
            const response = await api.postResetPassword(user.user_id);
            if (!response.error) {
                console.log(response.data[0].attributes.extra.aiuser);
                user.new_password = response.data[0].attributes.extra.aiuser.result.newpwd;
            } else {
                console.error(response);
            }
        },

        async copyToClipboard(user) {
            try {
                await navigator.clipboard.writeText(user.new_password);
                alert(this.$t('text-copied'));
                user.is_reset = false;
                console.log('文本已複制到剪貼薄');
            } catch (err) {
                console.error('无法复制文本: ', err);
            }
        }
    },

    computed: {
        availableGroupLevels() {
            if (this.selectedGroupDept !== null) {
                const selectedGroup = this.groupList.find(
                    (group) => group.dept === this.selectedGroupDept
                );
                return selectedGroup ? selectedGroup.lvl : [];
            }
            return [];
        },

        availableUserLevels() {
            if (this.editingUser !== null && this.editingUser.new_department !== null) {
                const selectedGroup = this.groupList.find(
                    (group) => group.dept === this.editingUser.new_department
                );
                return selectedGroup ? selectedGroup.lvl : [];
            }
            return [];
        },
    },

    async mounted() {
        this.refreshUserList();
        this.getAceessGroupList();
    },
}
</script>

<style scoped>
.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.popup-mask .popup-content {
    position: absolute;
    width: 30%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    border-radius: 30px;
    padding: 20px;
    min-width: 60%;
    max-height: 70%;
    overflow-y: scroll;
}

.popup-mask .popup-content::-webkit-scrollbar {
    display: none;
}

.popup-mask .popup-content .close {
    border-radius: 40px;
    width: 18px;
    font-size: 20px;
    float: right;
    color: gray;
    position: absolute;
    right: 23px;
    top: 13px;
    cursor: pointer;
}

.popup-mask .popup-content .title {
    margin: 20px 0;
    font-weight: bold;
    font-size: 15px;
}

.content .file-info {
    width: 100%;
    position: relative;
    padding: 0 10px;
}

.content .file-detail h6 {
    margin: 0;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: bold;
}

.content .file-detail .button {
    border-radius: 20px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    background-color: #F58A32;
    padding: 0px 10px;
    border: unset;
    width: 20%;
    margin: 5px 5px;
    line-height: 35px;
    font-weight: bold;
    align-content: center;
}

.content .file-detail .button:hover {
    background-color: #F05A27;
}

.content .file-detail .button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.content .file-detail .button.disable-button {
    background-color: darkgray;
    cursor: default;
}

.content .file-detail .button.cancel-button {
    background-color: transparent;
    color: gray;
}

.content .file-detail .dept-manage {
    text-align: left;
    margin-bottom: 100px;
}

.content .file-detail .dept-manage .dept-manage-list .add-button {
    padding: 0;
    width: 85px;
    height: 22px;
    margin: 5px;
    line-height: normal;
    border-radius: 20px;
    font-weight: normal;
}

.content .file-detail .dept-manage .dept-input input {
    margin-top: 20px;
    width: calc(100% - 210px);
    border: unset;
    outline: unset;
    border-bottom: 1px solid gray;
    font-size: 14px;
}

.content .file-detail .dept-manage .dept-input i {
    cursor: pointer;
    color: #8D8D91;
}

.content .file-detail .dept-manage .dept-list {
    margin-top: 20px;
}

.content .file-detail .dept-manage .dept-list ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.content .file-detail .dept-manage .dept-list ul button {
    list-style-type: none;
    background-color: #F58A32;
    margin: 0px 8px 5px 0px;
    border-radius: 20px;
    padding: 0 15px;
    color: #FFFFFF;
    border: 1px solid #F58A32;
    font-size: 12px;
    font-weight: bold;
}

.content .file-detail .dept-manage .dept-list ul li {
    list-style-type: none;
    background-color: peachpuff;
    margin: 0px 8px 5px 0px;
    border-radius: 20px;
    padding: 0 15px;
    color: #F05A27;
    border: 1px solid #F05A27;
    font-size: 12px;
    font-weight: bold;
}

.content .file-detail .dept-manage .dept-list ul li i {
    color: #FFFFFF;
    cursor: pointer;
}

.content .file-detail .user-manage {
    text-align: left;
}

.content .file-detail .user-manage .table {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.content .file-detail .user-manage .table .table-row-0 {
    display: flex;
    font-size: 12px;
}

.content .file-detail .user-manage .table .table-row-0 .table-cell {
    padding: 0 5px;
    display: flex;
    text-align: center;
}

.content .file-detail .user-manage .table .table-row-0 .table-cell button {
    width: 70px;
    font-size: 12px;
}

.content .file-detail .user-manage .table .table-row-0 .table-cell i {
    cursor: pointer;
    color: #8D8D91;
    margin: 0px 5px;
    padding: 0;
    font-size: 20px;
}

.content .file-detail .user-manage .table .table-row-0 input {
    width: 90%;
    border-radius: 25px;
    height: 25px;
    border: 1px solid gray;
}

.content .file-detail .user-manage .table .table-row-0 select {
    border-radius: 25px;
    height: 25px;
    border: 1px solid gray;
    padding: 0 10px;
}

.content .file-detail .user-manage .table .table-row-0 button {
    padding: 0;
    width: 115px;
    height: 25px;
    margin: 0;
    line-height: normal;
    border: 0;
    border-radius: 20px;
    background-color: #F58A32;
    color: #FFFFFF;
    font-weight: bold;
}

.content .file-detail .user-manage .table .table-row-0 button:hover {
    background-color: #F05A27;
}

.content .file-detail .user-manage .table .table-row-0 .table-cell .form-switch {
    padding: 0 9px;
    margin: 0;
}

.content .file-detail .user-manage .table .table-row-0 .table-cell .form-check-input {
    height: 25px;
    width: 45px;
    margin: 0;
    margin-bottom: 0.5rem;
    box-shadow: unset;
    border: 1px solid gray;
    background-color: gray;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e");
}

.content .file-detail .user-manage .table .table-row-0 .table-cell .form-check-input:checked {
    border: 1px solid #F58A32;
    background-color: #F58A32;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e");
}

.content .file-detail .user-manage .table .table-row-0 .table-cell .create-button {
    margin: 0 10px;
}

.content .file-detail .user-manage .table .table-row-1 {
    display: flex;
}

.content .file-detail .user-manage .table .table-row-1 .table-cell {
    padding: 10px;
}

.content .file-detail .user-manage .table .table-row-1 .table-cell p {
    font-size: 12px;
    color: gray;
    border-bottom: 1px solid gray;
    padding: 0;
    margin: 0;
}

.content .file-detail .user-manage .table .table-row {
    border-bottom: 1px solid lightgray;
    display: flex;
    align-items: center;
}

.content .file-detail .user-manage .table .table-row .table-cell {
    padding: 5px 10px;
}

.content .file-detail .user-manage .table .table-row .table-cell select {
    border-radius: 25px;
    height: 25px;
    border: 1px solid gray;
    padding: 0 10px;
}

.content .file-detail .user-manage .table .table-row .table-cell .form-check-input {
    height: 25px;
    width: 45px;
    margin: 0;
    margin-bottom: 0.5rem;
    box-shadow: unset;
    border: 1px solid gray;
    background-color: gray;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e");
}

.content .file-detail .user-manage .table .table-row .table-cell .form-check-input:checked {
    border: 1px solid #F58A32;
    background-color: #F58A32;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e");
}

.content .file-detail .user-manage .table .table-row .table-cell .button {
    width: auto;
    padding: 0 20px;
    line-height: 25px;
}

.content .file-detail .user-manage .table .table-row .table-cell.switch {
    padding: 0 5px;
}

.content .file-detail .user-manage .table .table-row .table-cell.new-password {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content .file-detail .user-manage .table .table-row .table-cell.new-password p {
    color: gray;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid lightgray;
    width: 60%;
    overflow-x: scroll;
}

.content .file-detail .user-manage .table .table-row .table-cell.new-password p::-webkit-scrollbar {
    display: none;
}

.content .file-detail .button-list {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.loading {
    position: relative;
    width: 20px;
    height: 20px;
}
</style>